const firebaseConfig = {
    apiKey: "AIzaSyBBs_jh1CuwLzzNY16miQBsEt4HX-fu1l4",
    authDomain: "aplikasi-penjualan-jagoreact.firebaseapp.com",
    databaseURL: "https://aplikasi-penjualan-jagoreact.firebaseio.com",
    projectId: "aplikasi-penjualan-jagoreact",
    storageBucket: "aplikasi-penjualan-jagoreact.appspot.com",
    messagingSenderId: "882565110977",
    appId: "1:882565110977:web:06f4a31efca54c51"
};


export default firebaseConfig;